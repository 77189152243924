import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5_f1500f464f97aacabd8ac52faab8a713/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/build/repo/middleware/auth.global.ts";
import check_45if_45onboarded_45global from "/opt/build/repo/middleware/checkIfOnboarded.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5_f1500f464f97aacabd8ac52faab8a713/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  check_45if_45onboarded_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}