import revive_payload_client_7UkUsbVt0K from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5_f1500f464f97aacabd8ac52faab8a713/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jP331mQRuo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5_f1500f464f97aacabd8ac52faab8a713/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WU8r16FdKg from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5_f1500f464f97aacabd8ac52faab8a713/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_zkSC1DJNB3 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5_f1500f464f97aacabd8ac52faab8a713/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_GVNZGAQgzH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5_f1500f464f97aacabd8ac52faab8a713/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_mvj85xSm4B from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_QKeZvk1ZbS from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5_f1500f464f97aacabd8ac52faab8a713/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_0O8Itn8zA6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5_f1500f464f97aacabd8ac52faab8a713/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cleave_HD9soKWDBf from "/opt/build/repo/plugins/cleave.ts";
import date_picker_client_l6L1nahZSs from "/opt/build/repo/plugins/date-picker.client.ts";
import axios_QMFgzss1s4 from "/opt/build/repo/plugins/axios.ts";
import bugsnag_client_TtQ636hzGt from "/opt/build/repo/plugins/bugsnag.client.ts";
import click_outside_D2NiLGp7dJ from "/opt/build/repo/plugins/click-outside.ts";
import error_handler_client_sQhJOBiqXf from "/opt/build/repo/plugins/error-handler.client.ts";
import eventBus_P6CbRuQa3W from "/opt/build/repo/plugins/eventBus.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_7UkUsbVt0K,
  unhead_jP331mQRuo,
  router_WU8r16FdKg,
  payload_client_zkSC1DJNB3,
  check_outdated_build_client_GVNZGAQgzH,
  plugin_vue3_mvj85xSm4B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QKeZvk1ZbS,
  chunk_reload_client_0O8Itn8zA6,
  cleave_HD9soKWDBf,
  date_picker_client_l6L1nahZSs,
  axios_QMFgzss1s4,
  bugsnag_client_TtQ636hzGt,
  click_outside_D2NiLGp7dJ,
  error_handler_client_sQhJOBiqXf,
  eventBus_P6CbRuQa3W,
  vue_query_wrmMkNpEpe
]